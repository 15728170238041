import React from "react";
import {
    Modal,ModalFooter,ModalHeader,
    Input,Button,
    InputGroup,InputGroupAddon, 
} from "reactstrap";
import utils from "../../../utils";
import Notification from "../../../components/Notification";
import InputMask from "react-input-mask";
const apiAdmin= require("../api/apiAdmin");

class Main extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            config : null,
            ticketLock:[],
            showModal:{
                updateConfig:false
            }
        }
    }

    toggle(component1,component2,value){
        let state = utils.getRawData(this.state);
        if(component2){
            if(value){
                state[component1][component2]  = value;
                this.setState(state);
            }
            else{
                state[component1][component2]  = !state[component1][component2];
                this.setState(state);
            }
        }
        else{
            if(value){
                state[component1]  = value;
                this.setState(state);
            }
            else{
                state[component1]  = !state[component1];
                this.setState(state);
            }
        }
    }

    inputOnchange(event){
        let typeMoney = [
            "MAXIMUM_AMOUNT_PER_DAY",
            "MAXIMUM_AMOUNT_PER_TIME",
            "MINIMUM_AMOUNT_PER_TIME",
            "MAX_ADD_MONEY",
            "TOTAL_CASH",
            "EXPRESS_DELIVERY_FEE",
            "NORMAL_DELIVERY_FEE",
            "HOLDING_FEE",
            "WITHDRAWAL_FEE",
            "WITHDRAWAL_ONLINE_FEE"
        ];
        if( typeMoney.indexOf(event.target.name)!== -1 ){
            let amount = event.target.value.replace(/[^\d]/g, "");
            if (utils.isEmpty(amount))
                amount = 0;
            try {
                amount = parseInt(amount);
                if (Number.isNaN(amount))
                    return;
                let config = Object.assign({},this.state.config)
                config[event.target.name] = amount;
                this.setState({config: config});
                return;
            } catch (e) {
                return;
            }
        }else{
            let config = Object.assign({},this.state.config)
            config[event.target.name] = event.target.value;
            this.setState({
                config: config
            });
        }
    }

    checkTime(time,text){
        let arrTime = time.split(":");
        if(arrTime.length !==3){
            Notification("error",`Sai định dạng thời gian ${text}`);
            this.toggle("showModal","updateConfig");
        }
        else if (arrTime[0] >= "24"){
            Notification("error",`Số giờ ${text} sai định dạng`);
            this.toggle("showModal","updateConfig");
        }
        else if (arrTime[1] >= "60"){
            Notification("error",`Số phút ${text} sai định dạng`);
            this.toggle("showModal","updateConfig");
        }
        else if (arrTime[2] >= "60"){
            Notification("error",`Số giây ${text} sai định dạng`);
            this.toggle("showModal","updateConfig");
        }
        else{
            return true
        }
    }

    handUpdateConfig(){
        if(!this.checkTime(this.state.config.GLOBAL_TIME_LIMIT,"không chấp nhận vé thường") ) return null
        if(!this.checkTime(this.state.config.OPEN_TIME,"bắt đầu làm việc") ) return null
        if(!this.checkTime(this.state.config.CLOSE_TIME,"thời gian đóng cửa") ) return null

        else if(this.state.config.MAX_ADD_MONEY%1000!==0 ){
            Notification("error","Số tiền tối đa / một lần nạp phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.TOTAL_CASH%1000!==0){
            Notification("error","Số tiền nạp tối đa / một ngày phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.MAXIMUM_AMOUNT_PER_DAY%1000!==0){
            Notification("error","Số tiền được rút tối đa / một lần phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.MAXIMUM_AMOUNT_PER_TIME%1000!==0){
            Notification("error","Số tiền được rút tối đa / một ngày phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.MINIMUM_AMOUNT_PER_TIME%1000!==0){
            Notification("error","Số tiền được rút tối đa / một ngày phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.EXPRESS_DELIVERY_FEE%1000!==0){
            Notification("error","Phí giao vé số nhanh phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.NORMAL_DELIVERY_FEE%1000!==0){
            Notification("error","Phí giao vé số thường phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.HOLDING_FEE%1000!==0){
            Notification("error","Phí giữ vé phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.WITHDRAWAL_FEE%1000!==0){
            Notification("error","Phí rút tiền tại quầy phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.WITHDRAWAL_ONLINE_FEE%1000!==0){
            Notification("error","Phí rút tiền online phải chia hết cho 1000");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.KENO_TIME_LIMIT< 0 ){
            Notification("error","Số phút không nhận vé ít nhất là 0");
            this.toggle("showModal","updateConfig");
        } else if(this.state.config.MAX_SMS_PER_DAY<0){
            Notification("error","Số OTP tối đa một tháng ít nhất là 0");
            this.toggle("showModal","updateConfig");
        } else{
            let config = Object.assign({},this.state.config);
            config.MAX_ADD_MONEY /= 1000;            config.MAX_ADD_MONEY  = config.MAX_ADD_MONEY.toString();
            config.TOTAL_CASH /= 1000;               config.TOTAL_CASH  = config.TOTAL_CASH.toString();
            config.MAXIMUM_AMOUNT_PER_DAY /= 1000;   config.MAXIMUM_AMOUNT_PER_DAY  = config.MAXIMUM_AMOUNT_PER_DAY.toString();
            config.MAXIMUM_AMOUNT_PER_TIME /= 1000;  config.MAXIMUM_AMOUNT_PER_TIME  = config.MAXIMUM_AMOUNT_PER_TIME .toString();
            config.MINIMUM_AMOUNT_PER_TIME /= 1000;  config.MINIMUM_AMOUNT_PER_TIME  = config.MINIMUM_AMOUNT_PER_TIME.toString();
            config.EXPRESS_DELIVERY_FEE /= 1000;     config.EXPRESS_DELIVERY_FEE  = config.EXPRESS_DELIVERY_FEE.toString();
            config.NORMAL_DELIVERY_FEE /= 1000;      config.NORMAL_DELIVERY_FEE  = config.NORMAL_DELIVERY_FEE.toString();
            config.HOLDING_FEE /=1000;               config.HOLDING_FEE = config.HOLDING_FEE.toString();
            config.WITHDRAWAL_FEE /=1000;            config.WITHDRAWAL_FEE = config.WITHDRAWAL_FEE.toString();
            config.WITHDRAWAL_ONLINE_FEE /=1000;     config.WITHDRAWAL_ONLINE_FEE = config.WITHDRAWAL_ONLINE_FEE.toString();
            config.KENO_TIME_LIMIT = config.KENO_TIME_LIMIT.toString();
            config.MAX_SMS_PER_DAY = config.MAX_SMS_PER_DAY.toString();
            config.TICKET_LOCK= this.state.ticketLock.join(',');

            apiAdmin.updateConfig(config,(err,result)=>  {
                if(err){
                    switch (err.data._error_message) {
                        case "Invalid time":
                            this.toggle("showModal","updateConfig",null)
                            Notification("error", "Lỗi", "Lỗi định dạng thời gian");
                            break;
                        default:
                            Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                            break;
                    }
                } else {
                    Notification("success","Cài đặt thành công","Cập nhật đã được thay đổi")
                    result.MAX_ADD_MONEY*= 1000;
                    result.TOTAL_CASH*= 1000;
                    result.MINIMUM_AMOUNT_PER_TIME*= 1000;
                    result.MAXIMUM_AMOUNT_PER_TIME*= 1000;
                    result.MAXIMUM_AMOUNT_PER_DAY*= 1000;
                    result.EXPRESS_DELIVERY_FEE*= 1000;
                    result.NORMAL_DELIVERY_FEE*= 1000;
                    result.HOLDING_FEE*= 1000;
                    result.WITHDRAWAL_FEE*= 1000;
                    result.WITHDRAWAL_ONLINE_FEE*= 1000;
                    this.setState({
                        config : result,
                        showModal : {updateConfig: false}
                    });
                }
            });
        }
    }
    componentDidMount(){
        apiAdmin.getConfig((err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                result.MAX_ADD_MONEY*= 1000;
                result.TOTAL_CASH*= 1000;
                result.MINIMUM_AMOUNT_PER_TIME*= 1000;
                result.MAXIMUM_AMOUNT_PER_TIME*= 1000;
                result.MAXIMUM_AMOUNT_PER_DAY*= 1000;
                result.EXPRESS_DELIVERY_FEE*= 1000;
                result.NORMAL_DELIVERY_FEE*= 1000;
                result.HOLDING_FEE*= 1000;
                result.WITHDRAWAL_FEE*= 1000;
                result.WITHDRAWAL_ONLINE_FEE*= 1000;
                if (result.TICKET_LOCK)
                {
                    this.setState({
                        config : result,
                        ticketLock:result.TICKET_LOCK.split(','),
                    });
                }
                else
                {
                    this.setState({
                        config : result,
                        ticketLock: [],
                    });
                }
            }
        });
    }
    handlePressLockTicket=(type)=>{
        
        let oldState=JSON.parse(JSON.stringify(this.state.ticketLock))
        if(oldState.includes(type)){
        oldState.splice(oldState.indexOf(type), 1);
        console.log(oldState)
        this.setState({ticketLock:oldState})
        }
        else {     
            oldState.push(type)      
            this.setState({ticketLock:oldState})
        }
    }
    render() {
        return(
            <React.Fragment>
                            <Modal isOpen={this.state.showModal.updateConfig}>
                                <ModalHeader>Xác nhận cập nhật thiết lập</ModalHeader>
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.toggle.bind(this,"showModal","updateConfig",null)}>
                                        Hủy bỏ
                                    </Button>
                                    <Button color="success" onClick = {this.handUpdateConfig.bind(this)}>
                                        Lưu
                                    </Button>
                                </ModalFooter>
                            </Modal>
                <center><div className={ utils.detectDevice(["fullhd","hd"],"or")? "text-center w-50 group-list-config" : "text-center w-100 group-list-config"} >
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend" style={{fontSize:"30"}}>Số tiền tối đa / một lần nạp</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="MAX_ADD_MONEY" 
                            value={ this.state.config === null ? 0 : this.state.config.MAX_ADD_MONEY.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Số tiền nạp tối đa / một ngày</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="TOTAL_CASH"
                            value={ this.state.config === null ? 0 : this.state.config.TOTAL_CASH.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Phí giao vé số nhanh</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="EXPRESS_DELIVERY_FEE"
                            value={ this.state.config === null ? 0 : this.state.config.EXPRESS_DELIVERY_FEE.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Phí giao vé số chậm</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="NORMAL_DELIVERY_FEE"
                            value={ this.state.config === null ? 0 : this.state.config.NORMAL_DELIVERY_FEE.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Phí giữ vé</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="HOLDING_FEE" 
                            value={ this.state.config === null ? 0 : this.state.config.HOLDING_FEE.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Phí rút tiền tại quầy</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="WITHDRAWAL_FEE" 
                            value={ this.state.config === null ? 0 : this.state.config.WITHDRAWAL_FEE.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Phí rút tiền online</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="WITHDRAWAL_ONLINE_FEE" 
                            value={ this.state.config === null ? 0 : this.state.config.WITHDRAWAL_ONLINE_FEE.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Số nhỏ nhất / một lần rút</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="MINIMUM_AMOUNT_PER_TIME"
                            value={ this.state.config === null ? 0 : this.state.config.MINIMUM_AMOUNT_PER_TIME.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Số tiền được rút tối đa / một lần</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="MAXIMUM_AMOUNT_PER_TIME"
                            value={ this.state.config === null ? 0 : this.state.config.MAXIMUM_AMOUNT_PER_TIME.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Số tiền được rút tối đa / một ngày</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="MAXIMUM_AMOUNT_PER_DAY"
                            value={ this.state.config === null ? 0 : this.state.config.MAXIMUM_AMOUNT_PER_DAY.getMoneyFormat()}
                            maxLength="15"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> VNĐ</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Không chấp nhận vé KENO khi còn</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="KENO_TIME_LIMIT" 
                            value={ this.state.config === null ? 0 : this.state.config.KENO_TIME_LIMIT}
                            maxLength="1"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg"> Phút</InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel" addonType="prepend">Không chấp nhận vé thường sau</InputGroupAddon>
                        <InputMask mask="99:99:99" 
                            name="GLOBAL_TIME_LIMIT" 
                            value={ this.state.config === null ? 0 : this.state.config.GLOBAL_TIME_LIMIT}
                            onChange={this.inputOnchange.bind(this)}
                        >
                            {(inputProps) => <Input 
                                    {...inputProps}
                                    bsSize = "lg"
                                    className="h-100"
                                    onChange= {inputProps.onChange}
                                />
                            }
                        </InputMask>
                        
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel"  addonType="prepend">Thời gian bắt đầu làm việc</InputGroupAddon>
                        <InputMask mask="99:99:99"
                            name="OPEN_TIME" 
                            value={ this.state.config === null ? 0 : this.state.config.OPEN_TIME}
                            onChange={this.inputOnchange.bind(this)}
                        >
                            {inputProps => <Input 
                                    {...inputProps}
                                    bsSize = "lg"
                                    className="h-100"
                                    onChange= {inputProps.onChange}
                                />
                            }
                        </InputMask>
                        
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel"  addonType="prepend">Thời gian đóng cửa</InputGroupAddon>
                        <InputMask mask="99:99:99"
                        name="CLOSE_TIME" 
                        value={ this.state.config === null ? 0 : this.state.config.CLOSE_TIME}
                        onChange={this.inputOnchange.bind(this)}
                        >
                            {inputProps => <Input 
                                    {...inputProps}
                                    bsSize = "lg"
                                    className="h-100"
                                    onChange= {inputProps.onChange}
                                />
                            }
                        </InputMask>
                        
                    </InputGroup>
                    <InputGroup className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel"  addonType="prepend">Số OTP tối đa một tháng</InputGroupAddon>
                        <Input 
                            bsSize = "lg"
                            className="h-100"
                            name="MAX_SMS_PER_DAY" 
                            value={ this.state.config === null ? 0 : this.state.config.MAX_SMS_PER_DAY} 
                            maxLength="2"
                            onChange={this.inputOnchange.bind(this)}
                        />
                        <InputGroupAddon classname="input-group-prepend-config-backlabel" addonType="prepend" bsSize = "lg">Lượt</InputGroupAddon>
                    </InputGroup>
                    <InputGroup style={{flexWrap:'nowrap',flexDirection:'row'}} className="mb-3 input-group-prepend-config" bsSize = "lg">
                        <InputGroupAddon className="input-group-prepend-config-frontlabel"  addonType="prepend">Khóa mua</InputGroupAddon>
                        <div style={{display:'flex'}}>
                        <Button style={{margin:5,padding:5}} color={!this.state.ticketLock.includes("1")?'secondary':'danger'} onClick={()=>{
                               this.handlePressLockTicket("1")
                            }} >
                                Mega {!this.state.ticketLock.includes("1")?'Mở':'Khóa'}
                            </Button>
                            <Button style={{margin:5,padding:5}} color={!this.state.ticketLock.includes("2")?'secondary':'danger'} onClick={()=>{
                               this.handlePressLockTicket("2")
                            }} >
                                Power {!this.state.ticketLock.includes("2")?'Mở':'Khóa'}
                            </Button>
                            <Button style={{margin:5,padding:5}} color={!this.state.ticketLock.includes("4")?'secondary':'danger'} onClick={()=>{
                               this.handlePressLockTicket("4")
                            }} >
                                Max3d {!this.state.ticketLock.includes("4")?'Mở':'Khóa'}
                            </Button>
                            <Button style={{margin:5,padding:5}} color={!this.state.ticketLock.includes("5")?'secondary':'danger'} onClick={()=>{
                               this.handlePressLockTicket("5")
                            }} >
                                Max3d+ {!this.state.ticketLock.includes("5")?'Mở':'Khóa'}
                            </Button>                           
                            <Button style={{margin:5,padding:5}} color={!this.state.ticketLock.includes("12")?'secondary':'danger'} onClick={()=>{
                               this.handlePressLockTicket("12")
                            }} >
                                Max3dPro {!this.state.ticketLock.includes("12")?'Mở':'Khóa'}
                            </Button>
                            <Button style={{margin:5,padding:5}} color={!this.state.ticketLock.includes("6")?'secondary':'danger'} onClick={()=>{
                               this.handlePressLockTicket("6")
                            }} >
                                Keno {!this.state.ticketLock.includes("6")?'Mở':'Khóa'}
                            </Button>
                            <Button style={{margin:5,padding:5}} color={!this.state.ticketLock.includes("8")?'secondary':'danger'} onClick={()=>{
                               this.handlePressLockTicket("8")
                            }} >
                                ĐT {!this.state.ticketLock.includes("8")?'Mở':'Khóa'}
                            </Button>
                            </div>
                    </InputGroup>
                    <center>
                        <Button className="w-50 input-group-prepend-config" onClick={this.toggle.bind(this,"showModal","updateConfig",null)}>
                            Cập nhật thiết lập
                        </Button>
                    </center>
                </div></center>  
            </React.Fragment>
        );
    }
}



export default Main;