// Auth
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import Clerk from "../pages/Clerk/main";
import Accountancy from "../pages/Accountancy/main";
import Admin from "../pages/Admin/main";
import Statistical from "../pages/statistical/main";

const dashboardRoutes = {
    path: "/dashboard",
    name: "Dashboard",
    header: "Main",
    containsHome: true,
    children: [
        {
            path: "/clerk",
            name: "Clerk",
            component: Clerk
        },
        {
            path: "/accountancy",
            name: "Accountant",
            component: Accountancy
        },
        {
            path: "/admin",
            name: "Admin",
            component: Admin
        },
        {
            path: "/accountancy/statistical",
            name: "Statistical",
            component: Statistical
        },
        {
            path: "/accountancy/history",
            name: "History",
            component: Accountancy
        },
    ]
};

const authRoutes = {
    path: "/auth",
    name: "Auth",
    children: [
        {
            path: "/",
            name: "Sign In",
            component: SignIn
        },
        {
            path: "/auth/reset-password",
            name: "Reset Password",
            component: ResetPassword
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500
        }
    ]
};


// Dashboard specific routes
export const dashboard = [
    dashboardRoutes
];

// Auth specific routes
export const page = [authRoutes];


// All routes
export default [
    dashboardRoutes,
];
