import React from "react";
import utils from "../../../utils";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import { Edit, Lock, Unlock } from "react-feather";
import html2canvas from 'html2canvas'
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification from "../../../components/Notification";
const { getNameDistrictFromCode } = require("../../../config/listDistrict");
const { getNameCityFromCode } = require("../../../config/listCity");
const { arrayCity } = require("../../../config/city");
const apiNotifi = require("./../api/apiNotifi");
const apiLocation = require("./../api/apiLocation");
export class notifiManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          id: 0,
          title: "đang tải",
          content: "đang tải",
          createdDate: "đang tải",
        },
      ],
      showModal: false,

      title: "",
      content: "",
    };
  }
  componentDidMount() {
    apiNotifi.listNotifiCommon(1, (d) => {
      console.log(d);
      this.setState({ ...this.state, data: d });
    });
  }
  toggle = () => {
    this.setState({ ...this.state, showModal: !this.state.showModal });
  };
  onInputChange = (event) => {
    console.log(event);
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  submitNotifi = () => {
    if (this.state.title == "" || this.state.content == "") {
      return;
    }
    this.getScreenShot(()=>{
    apiNotifi.createNotifiCommon({
      title: this.state.title,
      content: this.state.content,
      image:this.state.imgLink,
      imgCapture:this.state.imgCapture,
    },()=>{
      let data=this.state.data;
      data.push({id:0,title:this.state.title,content:this.state.content})
      this.setState({...this.state,data:data,showModal:false});

    });
  })
  };
  onImageFileChange(event) {
    if (event.target.files) {
        let state = Object.assign({}, this.state);
        let reader = new FileReader();
        reader.onload = e => {
            state.imgSrc = e.target.result;
            this.setState({localFile:state.imgSrc})
        };
       
        apiNotifi.apiAttachImage(event.target.files[0], (err, result) => {
                if (err) {
                    switch (err.data._error_message) {
                        case "Incomplete arguments 'attached_file'":
                            Notification("error","Yêu cầu thêm tệp đính kèm");
                            break;
                        case "Incomplete arguments 'contentType'":
                            Notification("error","Lỗi kiểu dữ liệu");
                            break;
                        case "Extension not allow":
                            Notification("error","Sai định dạng");
                            break;
                        case "Permission denied: ":
                            Notification("error","Không có quyền");
                            break;
                        case "Ticket not found":
                            Notification("error","Vé không còn tồn tại");
                            break;
                        case "Images is null":
                            Notification("error","Ảnh không được để trống");
                            break;
                        case "Too many Term Keno: ":
                            Notification("error","Quá nhiều kỳ");
                            break;
                        case "Nothing change for this Ticket":
                            Notification("error","Yêu cầu cập nhật thông tin vé");
                            break;
                        case "Product not found!":
                            Notification("error","Kiểu vé không hợp lệ");
                            break;
                        case "ProductOption not found!":
                            Notification("error","Kiểu chơi không hợp lệ");
                            break;
                        default:
                            Notification("error", "Có lỗi xảy ra ", "Dung lượng ảnh lớn hơn 5MB hoặc đường truyền lỗi");
                            break;
                    }
                } else {                        
                  console.log(result)              
                    this.setState({...this.state,imgLink:result.pathUrl});
                }
            });
        
        
        reader.readAsDataURL(event.target.files[0]);
    }
}
getScreenShot(callback){
  let c = document.getElementById('image'); // or document.getElementById('canvas');
 
  html2canvas(c).then((canvas)=>{
    var t = canvas.toDataURL().replace("data:image/png;base64,", "");
    console.log(t)
    
    apiNotifi.apiAttachImage(this.base64ToBlob(t,'image/png'), (err, result) => {
      if (err) {
          switch (err.data._error_message) {
              case "Incomplete arguments 'attached_file'":
                  Notification("error","Yêu cầu thêm tệp đính kèm");
                  break;
              case "Incomplete arguments 'contentType'":
                  Notification("error","Lỗi kiểu dữ liệu");
                  break;
              case "Extension not allow":
                  Notification("error","Sai định dạng");
                  break;
              case "Permission denied: ":
                  Notification("error","Không có quyền");
                  break;
              case "Ticket not found":
                  Notification("error","Vé không còn tồn tại");
                  break;
              case "Images is null":
                  Notification("error","Ảnh không được để trống");
                  break;
              case "Too many Term Keno: ":
                  Notification("error","Quá nhiều kỳ");
                  break;
              case "Nothing change for this Ticket":
                  Notification("error","Yêu cầu cập nhật thông tin vé");
                  break;
              case "Product not found!":
                  Notification("error","Kiểu vé không hợp lệ");
                  break;
              case "ProductOption not found!":
                  Notification("error","Kiểu chơi không hợp lệ");
                  break;
              default:
                  Notification("error", "Có lỗi xảy ra ", "Dung lượng ảnh lớn hơn 5MB hoặc đường truyền lỗi");
                  break;
          }
      } else {                              
          this.setState({...this.state,imgCapture:result.pathUrl},()=>{
            callback()
          });
      }
  });
  })
}
 base64ToBlob(base64, mime) 
{
    mime = mime || '';
    var sliceSize = 1024;
    var byteChars = window.atob(base64);
    var byteArrays = [];

    for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: mime});
}
  render() {
    const tableColumns = [
      {
        dataField: "id",
        text: "Id",
      },
      {
        dataField: "title",
        text: "Tiêu đề",
      },
      {
        dataField: "content",
        text: "Nội dung",
      },
      {
        dataField: "createdDate",
        text: "Tạo ngày",
      },
      {
        headerFormatter: (column, colIndex) => {
          return (
            <Button
              className="float-center m-2 btn-suscess text-white"
              color="success"
              onClick={() => this.toggle()}
            >
              Tạo thông báo
              <FontAwesomeIcon className="ml-2" icon={faStore} />
            </Button>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Modal style={{width:'90%',maxWidth:'none'}} isOpen={this.state.showModal}>
          <ModalHeader>Tạo thông báo</ModalHeader>
          <ModalBody>
          <div className="row">
          <div className="col-md-6">
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">Tiêu đề</InputGroupAddon>
              <Input  name="title" onChange={(e) => this.onInputChange(e)} />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">Nội dung</InputGroupAddon>
              <Input name="content" type='textarea' onChange={(e) => this.onInputChange(e)} />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">Ảnh thông báo</InputGroupAddon>
              <input  
                    type='file'
                    accept='image/png,image/jpeg'                  
                   // className='d-none'
                    onChange={this.onImageFileChange.bind(this)}
                    onClick={event => {
                        event.target.value = null;
                    }}
                />
                <image width={100} src={utils.getURLImage(this.state.imgLink)}/>
            </InputGroup>
            </div>

            <div className="col-md-6">
                    <div id={'image'} style={{borderWidth:1,borderRadius:5,padding:10,borderStyle:'solid',width:719,backgroundColor:'#ececec',overflow:'hidden'}}>
                      <p style={{fontWeight:'bold',fontSize:40}}>
                      {this.state.title}
                      </p>
                      <div style={{fontSize:28,whiteSpace:'pre-wrap'}}>
                      {this.state.content}
                      </div>
                      <img
                        src={this.state.localFile}
                        style={{width:'100%'}}
                      />
                    </div>
                    <div>
                      <Button color="secondary" onClick={() => {
                      this.getScreenShot()
                      }}>
                      </Button>
                    </div>
            </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              Hủy bỏ
            </Button>
            <Button color="success" onClick={()=>this.submitNotifi()}>Lưu</Button>
          </ModalFooter>
        </Modal>
        <div>
          <div className="overflow-x-auto table-overflow-x">
            <BootstrapTable
              bootstrap4
              bordered={true}
              keyField="id"
              data={this.state.data}
              columns={tableColumns}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default notifiManagement;
