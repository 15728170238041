const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require("axios");

function listNotifiCommon(page, callback) {
  axios({
    url: config_api.notifi + "?page=" + page,
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
    data: {},
  })
    .then((result) => {
      console.log(result.data);
      var map = result.data.map((e) => {
        return {
          id: e.id,
          title: e.data.title,
          content: e.data.content,
          createdDate: e.createdDate,
        };
      });
      return callback(map);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}
function createNotifiCommon(data, callback) {
  axios({
    url: config_api.notifi,
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
    data:JSON.stringify( data),
  })
    .then((result) => {
      return callback(false, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}
function apiAttachImage(file,callback) {
  if(file===null){
      return callback({data:{_error_message:"Chưa chụp được ảnh"}});
  }else if(file.size>5242880){
      return callback({data:{_error_message:"Ảnh quá lớn. Chọn lại ảnh"}});
  }
  var bodyFormData = new FormData();
  bodyFormData.append('attached_file', file);
  bodyFormData.append('contentType', "post");
  console.log(bodyFormData)
  axios({
      url: `${config_api.attachment}/`,
      method: 'POST',
      headers: {
          "Content-type": "multipart/form-data",
          "Authorization": 'Bearer ' + utils.getAuthToken()
      },
      data: bodyFormData
  })
  .then(result => {
      return callback(false, result.data);
  })
  .catch(error => {
      if(error.request){
          return callback("Đường truyền bị gián đoạn");
      }
      else if (error.response.status === 413){
          error.response.data._error_message="Ảnh quá lớn. Chọn lại ảnh";
          return callback(error.response);
      }
      else if (error.response.status === 422){
          error.response.data._error_message="Kiểm tra kết nối camera";
          return callback(error.response);
      }
      else if (error.response) {
          return callback(error.response);
      }
      else {
          return callback(error.message);
      }
  });
}
module.exports = {
  apiAttachImage,
  listNotifiCommon,
  createNotifiCommon,
};
