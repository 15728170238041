import React from "react";
import { Row, Label, FormGroup, Button,Table, ModalHeader,Modal, ModalBody,InputGroup,CustomInput } from "reactstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Notification from './../../../components/Notification';
import {convertDate,isEmpty,range,getURLImage} from '../../../utils/';
import {getActionHistoryById} from '../../../config/config';
import {rejectTicket} from '../api/apiStatistic'
import {listPos} from '../api/apiPos'
import {listStore} from '../api/apiStore'
import product from '../../../assets/json/product'
class Filter extends React.Component {
    render() {
        return (
            <span className="m-2 filter-input">
                <Label className="d-block content-title-bold m-0">{this.props.label}</Label>
                {
                    this.props.type==="text" ? (
                        this.props.smallOption ? (
                            <InputGroup className="mb-3 smallOption">
                                <CustomInput type="select" name={"option_"+this.props.name} className="smallOption-statistic d-inline" onChange={this.props.action}>
                                    <option key="0" value={""}>Tất cả</option>
                                    {
                                        this.props.smallOption.map((option,key)=>{
                                            return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                        })
                                    }
                                </CustomInput>
                                <input name={this.props.name||"nullName"} type="text" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onKeyUp={this.props.action}/>
                            </InputGroup>
                        ) : (
                            <input name={this.props.name||"nullName"} type="text" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onKeyUp={this.props.action}/>
                        )
                    ): this.props.type==="option" ?(
                        <select class="custom-select header-select-statistic" name={this.props.name||"nullName"} style={this.props.style} onChange={this.props.action}>
                            <option key="0" value={""}>Tất cả</option>
                            {
                                this.props.optionSelect.map((option,key)=>{
                                    return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                })
                            }
                        </select>
                    ): this.props.label
                }
            </span>
        );
    }
}

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromdate: null,
            todate: null,
            showModalImageTicket: false,
            imageTicketReturn : null,
            listPos:[],
            optionPos:[]
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label:"STT"},
            {label:"ID Vé"},
            {label:"User nhân viên"},
            {label:"Họ tên nhân viên"},
            {label:"SĐT"},
            {label:"Quyền"},
            {label:"Mã POS"},
            {label:"Cửa hàng"},
            {label:"CMT/CCCD"},
            {label:"Loại vé"},
            {label:"Trị giá"},
            {label:"Kiểu hủy"},
            {label:"Thời gian mua"},
            {label:"Thời gian hủy"},
        ];
        this.roles = [
            {label:"Bán vé",value:"clerk"},
            {label:"Hệ thống",value:"system"},
            {label:"Kế toán",value:"acountcy"},
        ];
        this.actionId=[
            {label:"Hủy vé và hoàn trả vé cho vietlott",value:"60"},
            {label:"Hủy vé và không hoàn trả vé cho vietlott",value:"61"},
            {label:"Hệ thống tự hủy vé",value:"62"},
            {label:"Nhân viên hủy vé",value:"63"},
        ];
        this.product=[
            {label:"Mega 6/45",value:"1"},
            {label:"POWER 6/55",value:"2"},
            {label:"MAX 4D",value:"3"},
            {label:"MAX 3D",value:"4"},
            {label:"MAX 3D+",value:"5"},
            {label:"Keno",value:"6"},
            {label:"MAX 3d Pro",value:"12"},
        ]
        this.filter={
            size: 100,
            page:1
        };
        this.listStore=[];
    }

    toggle(component){
        this.setState({
            [component]: !this.state[component]
        });
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }



    getRejectTicket(page){
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
            Object.assign(this.filter,{page: this.filter.page});
        }
        Object.assign(this.filter,{fromdate: convertDate(this.state.fromdate)},{todate: convertDate(this.state.todate)})
        rejectTicket(
            this.filter,
            (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({statisticData: result, pageNum : Math.ceil(result.count/this.filter.size)})
            }
        });
    }

    handOnclickRow(imgLink,actionId){
        if([60,61].includes(actionId)){
            let state = Object.assign({}, this.state);
            state.showModalImageTicket = true;
            state.imageTicketReturn = imgLink
            this.setState(state);
        }
    }

    listPos(){
        listPos({},(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let resultOptionPos = [];
                let resultListPos = [];
                result.map(pos=>{
                    resultOptionPos.push({value: pos.code, label: pos.code});
                    resultListPos.push({value: pos.id, label: pos.code});
                })
                this.setState({optionPos: resultOptionPos, listPos: resultListPos})
            }
        });
    }

    getStore(){
        listStore((err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường hoặc báo cho quản trị viên");
            } else {
                result.map((value) => {
                    this.listStore.push({label:value.storeName, value: value.id})
                });
            }
        });
    }

    getPosById( id ){
        if(!id) return "-";
        try {
            let pos = this.state.listPos.find((pos)=>pos.value == id);
            if(pos) return pos.label;
            else return "-";
        } catch{
            return "-"
        }
    }

    getStoreById( id ){
        if(!id) return "-";
        try {
            let pos = this.listStore.find((pos)=>pos.value == id);
            if(pos) return pos.label;
            else return "-";
        } catch{
            return "-"
        }
    }


    componentDidMount(){
        this.getRejectTicket(1)
        this.listPos();
        this.getStore();
    }
    render() {
        return(
            <React.Fragment>
                        <Modal isOpen={this.state.showModalImageTicket}>
                            <ModalHeader toggle={this.toggle.bind(this,"showModalImageTicket")}>
                                Ảnh vé bị hủy
                            </ModalHeader>
                            <ModalBody>
                                <img
                                    alt="ảnh vé bị hủy"
                                    src={getURLImage(this.state.imageTicketReturn)}
                                    className="imageReturn"
                                />
                            </ModalBody>
                        </Modal>
                <div className='mx-4 p-3 w-120  text-center'>
                    <h2 className="d-inline">
                        {`Tổng số vé bị hủy: ${this.state.statisticData ? this.state.statisticData.count: null}`}
                    </h2>
                    <Row className='justify-content-center m-0 mt-2'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block">Ngày bắt đầu</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.fromdate===null ? new Date(): this.state.fromdate}
                                onChange={date =>this.setState({fromdate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <FormGroup className='mb-3 ml-3'>
                            <Label className="d-block">Ngày kết thúc</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.todate===null ? new Date(): this.state.todate}
                                onChange={date => this.setState({todate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <div className='mt-4 p-2'>
                            <Button color='primary' onClick={this.getRejectTicket.bind(this,1)}>
                                Tìm kiếm
                            </Button>
                            <ReactHTMLTableToExcel
                                id="table-xls-button"
                                className="download-table-xls-button float-right d-inline mx-2"
                                table="thong-ke-khach-hang"
                                filename={`THỐNG KÊ HỦY VÉ TỪ ${this.filter.fromdate} ĐẾN ${this.filter.todate}`}
                                sheet={`sheet1`}
                                buttonText="Tải xuống"
                            />
                        </div>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Filter label="Chọn loại vé"   type="option" name="productId[]"  optionSelect={this.product} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Chọn POS"   type="option" name="pos[]"  optionSelect={this.state.optionPos} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Chọn kiểu hủy"   type="option" name="actionId[]"  optionSelect={this.actionId} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Chọn quyền"   type="option" name="role[]"  optionSelect={this.roles} action={this.inputOnchange.bind(this)}/>
                    </Row>
                </div>
                <Row className="d-flex justify-content-center mx-4 mt-2 w-120 ">
                    {
                        this.state.statisticData?(
                            <Table className="table-bordered m-0">
                                <thead>
                                    <tr colspan={6}></tr>
                                    <tr>
                                        <td>3D</td>
                                        <td>4D</td>
                                        <td>MEGA</td>
                                        <td>POWER</td>
                                        <td>KENO</td>
                                        <td>3D Pro</td>
                                        <td>TỔNG</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td>{(this.state.statisticData.total[4]+this.state.statisticData.total[5]).getMoneyFormat()}</td>
                                    <td>{(this.state.statisticData.total[3]).getMoneyFormat()}</td>
                                    <td>{(this.state.statisticData.total[1]).getMoneyFormat()}</td>
                                    <td>{(this.state.statisticData.total[2]).getMoneyFormat()}</td>
                                    <td>{(this.state.statisticData.total[6]).getMoneyFormat()}</td>
                                    <td>{(this.state.statisticData.total[12]).getMoneyFormat()}</td>
                                    <td>{(this.state.statisticData.total[0]).getMoneyFormat()}</td>
                                </tbody>
                            </Table>
                        ):null
                    }
                </Row>
                <div className=' mx-4 p-3 mt-2 w-120  text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                <tr>
                                    {
                                        this.tableheader1.map((header)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isEmpty(this.state.statisticData) ? null :
                                    this.state.statisticData.rows.map((row,key,)=>{
                                        return(
                                            <tr key={key} className="cursor-pointer" onClick={this.handOnclickRow.bind(this,row.ticket.imageFront,row.actionId)}>
                                                <td>{(this.filter.page-1)*this.filter.size+key+1}</td>
                                                <td>{row.ticket.id || "-"}</td>
                                                <td>{row.staff.username || "-"}</td>
                                                <td>{row.staff.fullName || "-"}</td>
                                                <td>{row.staff.phoneNumber || "-"}</td>
                                                <td>{row.staff.role || "-"}</td>
                                                <td>{this.getPosById(row.staff.posId) || "-"}</td>
                                                <td>{this.getStoreById(row.staff.storeId) || "-"}</td>
                                                <td>{row.staff.idCardNumber || "-"}</td>
                                                <td>{product[row.ticket.productId] || "-"}</td>
                                                <td>{row.transaction_history.value.getMoneyFormat() || "-"}</td>
                                                <td>{getActionHistoryById(row.actionId) || "-"}</td>
                                                <td>{row.ticket.createdDate || "-"}</td>
                                                <td>{row.createdDate || "-"}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <Row className="mt-2">
                        <select class="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            {
                                isEmpty(this.state.statisticData) ? null
                                :(
                                    <div className="float-right px-2">
                                        <Button disabled={this.filter.page===1} onClick={this.getRejectTicket.bind(this,"-")}>{`<`}</Button>
                                        {
                                            range(1,10).map((index)=>{
                                                return(
                                                    <Button 
                                                        className={`${this.filter.page === index ? "btn-danger" : null}`}
                                                        hidden={index >  this.state.pageNum}
                                                        onClick={this.getRejectTicket.bind(this,index)}
                                                    >
                                                        {index}
                                                    </Button>
                                                )
                                            })
                                        }
                                        <Button hidden={this.filter.page <= 10|| this.filter.page===this.state.pageNum} className="btn-danger">{`${this.filter.page}`}</Button>
                                        <Button hidden={this.state.pageNum<=10} className={`${this.filter.page === this.state.pageNum ? "btn-danger" : null}`} onClick={this.getRejectTicket.bind(this,this.state.pageNum)}>{`...${this.state.pageNum}`}</Button>
                                        <Button disabled={this.filter.page >=  this.state.pageNum} onClick={this.getRejectTicket.bind(this,"+")}>{`>`}</Button>
                                    </div>
                                )
                            }
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
