import React from "react";

import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import "./css/clerk.css";
import utils from "../../utils";
import Notification from "../../components/Notification";
import Block from "./block";
import io from 'socket.io-client';
import { Menu } from "react-feather";
import ReactNotifications from 'react-browser-notifications';
const config_api_socket = require("../../config/config");
const apiListOder = require("./api/apiListOder");
const apiAward = require("./api/apiAward");

class SidebarLeft extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                waiting_for_handling_keno: [],
                waiting_for_handling_normal: [],
                waiting_for_redeem: [],
                handling: [],
                handling_redeem: [],
            }
        };
        this.NotificationBody = [];
    }

    handleOnClickTabLeft(key) {
        if(utils.isEmpty(this.state.data.handling)){
            let phoneNumber = key.split("-")[0];
            let index = key.split("-")[1];
            let data = utils.getRawData(this.state.data);
            let oderSelected = data[phoneNumber].splice(index, 1)[0];
            apiListOder.getTicket(oderSelected.idOder,(err,result)=>  {
                if(err){
                    switch (err.data._error_message) {
                        case "Can not get this order at the moment":
                            Notification("error", "Đã có người lấy đơn","Chọn đơn khác");
                            this.setState({data: data});
                            break;
                        case "Staff has another order processing":
                            Notification("error", "Đã có người lấy đơn","Chọn đơn khác");
                            this.setState({data: data});
                            break;
                        case "Order not found!":
                            Notification("error", "Order không tồn tại","Chọn đơn khác");
                            this.setState({data: data});
                            break;
                        case "(Many) Ticket not found!":
                            Notification("error", "Có vé không hợp lệ","Vui lòng hủy tất cả vé");
                            break;
                        case "Permission denied: ":
                            Notification("error", "Không phải vé của khách hàng");
                            break;
                        case "Ticket not found":
                            Notification("error", "Không tìm thấy vé số");
                            break;
                        default:
                            Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                            break;
                    }
                } else {
                    data.handling.push(oderSelected);
                    this.setState({data: data},()=>{
                        this.props.moveDataToContent(result)
                    });
                }
            });
        }
        else{
            Notification("error","Đang có vé chưa xử lý xong")
        }
    }

    handleOnClickTabRight(key) {
        let type = key.split("-")[0];
        let data = Object.assign({}, this.state.data);
        let oderSelected = data[type][0];
        this.props.moveDataToContent(oderSelected.rawData);
    }

    handleOnClickTabRedeem(key) {
        if( utils.isEmpty(this.props.dataRedeem)){
            let index = key.split("-")[1];
            let data = Object.assign({}, this.state.data);
            apiAward.getRequestRedeem(this.state.data.waiting_for_redeem[index].id,(err,result)=>{
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                    
                }
                else{
                    let dataSelect = data.waiting_for_redeem.splice(index,1);
                    data.handling_redeem.push(dataSelect[0])
                    this.props.moveDataToContent(null,result);
                }
            })
            this.setState({data: data});
        } else{
            Notification("error", "Đang có yêu cầu rút tiền chưa xử lý xong");
        }
    }

    showNotifications() {
        if(this.n.supported()) this.n.show();
    }

    handleClickNotification(event) {
        window.focus()
        this.n.close(event.target.tag);
    }
    
    fnListOder(Notification){
        apiListOder.listOder((err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.NotificationBody = [];
                let lengthDataTicketCurent = {
                    waiting_for_handling_keno : this.state.data.waiting_for_handling_keno,
                    waiting_for_handling_normal : this.state.data.waiting_for_handling_normal,
                }

                let state = Object.assign({}, this.state);
                state.data.waiting_for_handling_keno = [];
                state.data.waiting_for_handling_normal = [];

                result.map(((value)=>{
                    if(value.productId===6){
                        state.data.waiting_for_handling_keno.push(
                            {
                                phoneNumber: value.customer.phoneNumber,
                                time: value.createdDate,
                                price: value.totalPrice,
                                idOder : value.id,
                                ticketArray: value.ticketArray,
                                termArray: value.ticket.termArray,
                                
                            }
                        )
                    }else{
                        state.data.waiting_for_handling_normal.push(
                            {
                                phoneNumber: value.customer.phoneNumber,
                                time: value.createdDate,
                                price: value.totalPrice,
                                idOder : value.id,
                                ticketArray: value.ticketArray,
                                productId:value.productId
                            }
                        )
                    }
                    return null;
                }))
                if(state.data.waiting_for_handling_keno > lengthDataTicketCurent.waiting_for_handling_keno){
                    this.NotificationBody.push("Order vé KENO mới.");
                }
                if(state.data.waiting_for_handling_normal > lengthDataTicketCurent.waiting_for_handling_normal){
                    this.NotificationBody.push("Order vé THƯỜNG mới.");
                }
                    


                this.setState(state);
                if(Notification===true && this.NotificationBody.length!==0 && this.n.supported()){
                    this.n.show();
                }
            }
        });
    }

    fnListRedeem(){
        apiAward.listRequestRedeem(15,"type=11",(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({},this.state);
                state.data.waiting_for_redeem = result;
                this.setState(state);
            }
        })
    }

    moveBlock(tabMove,indexItemMove,tabAdd){
        let data = Object.assign({},this.state.data)
        let dataSelect = data[tabMove].splice(indexItemMove,1);
        data[tabAdd].push(dataSelect[0])
        return data;
    }

    componentDidMount(){
        const socket = io.connect(
            config_api_socket.socket_server,
            {query:  `storeId=${utils.getUserinfo().storeId}&username=${utils.getUserinfo().username}`}
        );
        apiListOder.getOderProcessing((err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({}, this.state);
                result.map(((value)=>{
                    state.data.handling.push(
                        {
                            phoneNumber: value.customer.phoneNumber,
                            time: value.createdDate,
                            price: value.totalPrice,
                            idOder : value.id,
                            ticketArray: value.ticketArray,
                            rawData:value
                        }
                    );
                    return null;
                }));
                this.setState(state);
            }
        });
        this.fnListOder();
        this.fnListRedeem();
        socket.on('new_order',this.fnListOder.bind(this,true));
        socket.on('new_banking_request',this.fnListRedeem.bind(this));
    }

    UNSAFE_componentWillReceiveProps(nextPrors){
        // xoa dl trong tab dang xu ly rut tien
        if (utils.isEmpty(nextPrors.dataRedeem) && this.props.tabSelected === "redeem") {
            let data = Object.assign({},this.state.data);
            data.handling_redeem = [];
            this.setState({data:data});
        }
        // xoa dl trong tab dang xu ly ban ve
        if (utils.isEmpty(nextPrors.dataTickets) && this.props.tabSelected === "order") {
            let data = Object.assign({},this.state.data);
            data.handling = [];
            this.setState({data:data});
        }
    }

    render(){
        if(this.props.tabSelected==="redeem"){
            return(
                <Tabs>
                    <TabList>
                        <button className='float-left bg-white border-0 hidden-lg-up' onClick={()=>this.props.toggle("showSidebar")} > <Menu/> </button>
                        <Tab style={{maxWidth: "50%",height:"100%", paddingLeft:"1%", minHeight:"80px"}}>Y/c rút tiền ({this.state.data.waiting_for_redeem.length}) </Tab>
                        <Tab style={{maxWidth: "50%",height:"100%", paddingLeft:"1%", minHeight:"80px"}}>Đang xử lý ({this.state.data.handling_redeem.length})</Tab>
                    </TabList>
                    <TabPanel>
                        {
                            this.state.data.waiting_for_redeem.length === 0
                                ? "Danh sách trống"
                                :
                                this.state.data.waiting_for_redeem.map((value, index) => {
                                    return (
                                        <Block key={`waiting_for_redeem-${index}`}
                                                id={`waiting_for_redeem-${index}`}
                                                phoneNumber={value.customer.phoneNumber}
                                                time={value.createdDate}
                                                price={value.amount}

                                                handleOnClick={this.handleOnClickTabRedeem.bind(this)}
                                        />
                                    )
                                })
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            this.state.data.handling_redeem.length === 0
                                ? "Danh sách trống"
                                :
                                this.state.data.handling_redeem.map((val, index) => {
                                    return (
                                        <Block key={`handling_redeem-${index}`}
                                                id={`handling_redeem-${index}`}
                                                phoneNumber={val.customer.phoneNumber}
                                                time={val.createdDate}
                                                price={val.amount}
                                        />
                                    )
                                })
                        }
                    </TabPanel>
                </Tabs>
            )
        } else{
            return(
                <React.Fragment>
                    <Tabs>
                        <TabList>
                            <button className='float-left bg-white border-0 hidden-lg-up' onClick={()=>this.props.toggle("showSidebar")} > <Menu/> </button>
                            <Tab style={{maxWidth: "33%",height:"100%", paddingLeft:"1%", minHeight:"80px"}}>Vé KENO ({this.state.data.waiting_for_handling_keno.length})&emsp; </Tab>
                            <Tab style={{maxWidth: "33%",height:"100%", paddingLeft:"1%", minHeight:"80px"}}>Vé thường ({this.state.data.waiting_for_handling_normal.length})</Tab>
                            <Tab style={{maxWidth: "34%",height:"100%", paddingLeft:"1%", minHeight:"80px"}}>Đang xử lý ({this.state.data.handling.length})&emsp;&emsp;</Tab>
                        </TabList>
                        <TabPanel>
                            {
                                this.state.data.waiting_for_handling_keno.length === 0
                                    ? "Danh sách trống"
                                    :
                                    this.state.data.waiting_for_handling_keno.map(({phoneNumber, time, price,termArray}, index) => {
                                        return (
                                            <Block key={`waiting_for_handling_keno-${index}`}
                                                    id={`waiting_for_handling_keno-${index}`}
                                                    phoneNumber={phoneNumber}
                                                    time={time}
                                                    price={price}
                                                    handleOnClick={this.handleOnClickTabLeft.bind(this)}
                                                    kenoterm={termArray}
                                            />
                                        )
                                    })
                            }
                        </TabPanel>
                        <TabPanel>
                            {
                                this.state.data.waiting_for_handling_normal.length === 0
                                    ? "Danh sách trống"
                                    :
                                    this.state.data.waiting_for_handling_normal.map(({phoneNumber, time, price,productId}, index) => {                                        
                                        return (
                                            <Block key={`waiting_for_handling_normal-${index}`}
                                                    id={`waiting_for_handling_normal-${index}`}
                                                    phoneNumber={phoneNumber}
                                                    time={time}
                                                    price={price}
                                                    handleOnClick={this.handleOnClickTabLeft.bind(this)}
                                                    isDt={productId>=7 && productId<12}
                                            />
                                        )
                                    })
                            }
                        </TabPanel>
                        <TabPanel>
                            {
                                this.state.data.handling.length === 0
                                    ? "Danh sách trống"
                                    :
                                    this.state.data.handling.map(({phoneNumber, time, price}, index) => {
                                        return (
                                            <Block key={`handling-${index}`}
                                                    id={`handling-${index}`}
                                                    phoneNumber={phoneNumber}
                                                    time={time}
                                                    price={price}
                                                    handleOnClick={this.handleOnClickTabRight.bind(this)}
                                            />
                                        )
                                    })
                            }
                        </TabPanel>
                    </Tabs>

                    <ReactNotifications
                        onRef={ref => (this.n = ref)} // Required
                        title="Cập nhật mới" // Required
                        body={this.NotificationBody.length===1 ? this.NotificationBody[0]:"Order vé KENO + vé THƯỜNG."} // Required
                        icon="https://vietluck.vn/favicon.ico"
                        tag="vietluck"
                        timeout="3000"
                        onClick={event => this.handleClickNotification(event)}
                    />
                </React.Fragment>
            )
        }
        
    }
}


export default SidebarLeft;