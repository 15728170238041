import React from "react";

import {
    Col, Row
} from "reactstrap";


import "react-tabs/style/react-tabs.css";
import "./css/clerk.css";
import "./css/hidden.css";

import SidebarLeft from "./sidebarLeft";
import SidebarRight from "./sidebarRight";
import Content from "./content";
import utils from "../../utils";


class Clerk extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            dataTicket: null,
            dataRedeem: null,
            clearDataHandling: false,
            showSidebar: false,
            side:{
                completed: [],
                canceled: []
            },
            sideRightRedeem:{
                completed: [],
                canceled: []
            },
            tabSelected : "order"
        }
    }

    toggle(component) {
        if(component === "clearDataHandling"){
            this.setState({
                [component]: !this.state[component],
                dataTicket: null,
            })
        }
        else{
            this.setState({
                [component]: !this.state[component]
            })
        }
    }

    moveDataToContent(dataTicket,dataRedeem){
        if(dataTicket===null){
            if(this.state.dataRedeem===null){
                this.setState({
                    dataRedeem: dataRedeem,
                    tabSelected: "redeem",
                    showSidebar: false,
                })
            }
        } else{
            if(this.state.dataTicket===null){
                this.setState({dataTicket: dataTicket, tabSelected:"order",showSidebar: false, });
            } else{
                this.setState({tabSelected:"order"});
            }
        }
        
    }

    handTicketChangeStatus(data,tab){
        let state = utils.getRawData(this.state);
        // neu sidebar dai qua thi gioi han 10 block
        if(state.side[tab].length >= 10){
            state.side[tab].pop();
        }
        state.side[tab].unshift(data);
        this.setState(state);
    }

    handRedeemChangeStatus(data,tab){
        let state = utils.getRawData(this.state);
        // neu sidebar dai qua thi gioi han 10 block
        if(state.sideRightRedeem[tab].length >= 10){
            state.sideRightRedeem[tab].pop();
        }
        state.sideRightRedeem[tab].unshift(data);
        this.setState(state);
    }

    clearDataRedeem(status){
        let state = utils.getRawData(this.state);
        state.sideRightRedeem[status].unshift({
            phoneNumber: this.state.dataRedeem.customer.phoneNumber,
            time: this.state.dataRedeem.createdDate,
            price: this.state.dataRedeem.amount 
        })
        state.dataRedeem= null;
        this.setState(state);
    }

    changeTab(tab){
        this.setState({tabSelected:tab})
    }

    render() {
        let uesrinfo = utils.getUserinfo();
        if(utils.isEmpty(uesrinfo) || uesrinfo.role!=="clerk") window.location.replace("/");
        else if(utils.checkAuth())
        return (
            <React.Fragment>
                        <div className="group-accountant">
                            <Row className="p-0 m-0 clerk">
                                <Col xs={2} className="p-0 m-0 group-tabs-handle border-right" >
                                    {/* <SidebarLeft
                                        moveDataToContent = {this.moveDataToContent.bind(this)}
                                        clearDataHandling = {this.state.clearDataHandling}
                                        toggle = {this.toggle.bind(this)}
                                        dataRedeem = {this.state.dataRedeem}
                                        dataTickets = {this.state.dataTicket}
                                        tabSelected = {this.state.tabSelected}
                                        setTab = {this.changeTab.bind(this)}
                                    /> */}
                                </Col>
                                <Col xs={8} className="group-content">
                                    <div className="main-content">
                                        <Content
                                            dataTickets = {this.state.dataTicket}
                                            dataRedeem = {this.state.dataRedeem}
                                            handTicketChangeStatus = {this.handTicketChangeStatus.bind(this)}
                                            handRedeemChangeStatus = {this.handRedeemChangeStatus.bind(this)}
                                            clearDataRedeem = {this.clearDataRedeem.bind(this)}
                                            toggle = {this.toggle.bind(this)}
                                            tabSelected = {this.state.tabSelected}
                                            setTab = {this.changeTab.bind(this)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={2} className="p-0 m-0 group-tabs-handle border-left" >
                                    <SidebarRight
                                        data = {this.state.tabSelected==="order" ? this.state.side : this.state.sideRightRedeem}
                                    />
                                </Col>
                            </Row>
                        </div>
                 
            </React.Fragment>
        )
    }
}

export default Clerk;