import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { Row, Label, FormGroup, Button,Table,Col, InputGroup,CustomInput,ModalHeader,Modal,ModalBody, Card, CardTitle, CardBody} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter,selectFilter } from "react-bootstrap-table2-filter";
import Notification from "../../../components/Notification";
import utils from "../../../utils";
import DatePicker from "react-datepicker";
import {exportedExcel} from '../../../components/api/apiExportServer';
import LoadingSprinner from '../../../components/LoadingSprinner';
import {getTicketInfo} from '../api/apiAdmin'
import moment from 'moment';
import {listPos} from '../api/apiPos';
import Select from "react-select";
const apiAdmin = require("./../api/apiAdmin");
const apiStatistic = require("../api/apiStatistic");
const {getProductById,getProductOptionById} = require('../../../assets/js/getProduct');
class ModalTicket extends React.Component {
    
   
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.showModalTicket}>
                    <ModalHeader toggle={this.props.closeModal}>   
                    {`Nhân viên in vé: ${this.props.staff.fullName}, id:${this.props.staff.id}, username:${this.props.staff.username}`}                   
                    </ModalHeader>
                    <ModalBody>
                        {                            
                            this.props.ticketArray.map((ticket,key)=>{
                                let price=0;
                                for(let val of ticket.value){
                                    price+=val.price;
                                }
                                price*=ticket.termArray.length;
                                return(
                                    <Card key = {key}>
                                    <CardTitle>{`Kiểu chơi: ${getProductOptionById(ticket.productOptionId)}, Tiền vé:${utils.getMoneyFormat(price)}, Số kỳ: ${ticket.termArray.length}, Id:${ticket.id} `}</CardTitle>
                                        <CardBody className="pt-0">
                                            { // hien so
                                                ticket.value.map((rowNumber,index)=>{
                                                    return(
                                                        <div className="d-flex">
                                                            <div className="d-flex align-items-center w-10">
                                                                <span class="text-danger">{String.fromCharCode(65+index)}</span>
                                                            </div>
                                                            <div className="d-flex flex-wrap w-90">
                                                                {
                                                                    rowNumber.number.map((number,k)=>{
                                                                        if(ticket.productOptionId===82){
                                                                            let kenoEvenOddBigSmall={
                                                                                "1": "Lẻ",
                                                                                "2": "Chẵn",
                                                                                "3": "Nhỏ",
                                                                                "4": "Lớn",
                                                                                "5": "Hòa Chẵn-Lẻ",
                                                                                "6": "Hòa Lớn-Nhỏ",
                                                                                "7": "Chẵn 11-12",
                                                                                "8": "Lẻ 11-12",

                                                                            };
                                                                            return (<span class="mx-1" key={k}>{kenoEvenOddBigSmall[number]}</span>)
                                                                        }
                                                                        return(<span class="mx-1" key={k}>{number}</span>)
                                                                    })
                                                                }
                                                                <span>-{rowNumber.price}K</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* Ảnh */}
                                            <Row>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" className="w-90" src={utils.getURLImage(ticket.imageFront)}/>
                                                </Col>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" className="w-90" src={utils.getURLImage(ticket.imageBack)}/>
                                                </Col>
                                            </Row>
                                           
                                        </CardBody>
                                    </Card>
                                    
                                )
                            })
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}
class Main extends React.Component {
    constructor(props) {
        super(props);
        // Set the initial state with toDate one day ahead of fromDate
        const fromDate = new Date();
        const toDate = moment(fromDate).add(1, 'days').toDate();
        this.state = {
            ticketHistory: null,
            fromDate: fromDate,
            toDate: toDate,
            pageNum: 0,
            listPos: [],
            productList: {
                "dt": [
                    "Điện toán 123",
                    "Điện toán 6x36",
                    "Lô tô 2 cặp",
                    "Lô tô 2 số",
                    "Lô tô 3 cặp",
                    "Lô tô 3 số",
                    "Lô tô 4 cặp",
                    "Lô tô 5 số",
                    "Thần tài 4"
                ],
                "vl": [
                    "Keno",
                    "MAX 3D",
                    "MAX 3D PRO",
                    "MAX 3D+",
                    "Mega 6/45",
                    "POWER 6/55"
                ]
            },
            dataTicket:null,
            showModalTicket:false
        };
        this.rowPage = [50,100,200,250];
        this.filter={
            size: 100,
            page:1
        };
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }
    handleToggleSelectPos(optionSelected){
        if(optionSelected.value === "toggle display pos inactive"){
            let optionShowPosInactive = !this.state.optionShowPosInactive;
            this.filter.code = "All";
            this.setState({
                listPos: optionShowPosInactive ? this.allOptionPos:this.activeOptionPos,
                optionShowPosInactive: optionShowPosInactive
            })
        } else{
            this.filter.code = optionSelected.value;
        }
    }
    fnGetDepositWinPrize(page) {
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
            Object.assign(this.filter,{page: this.filter.page});
        }
        if(this.state.fromDate===null) Object.assign(this.filter,{fromDate: "01-01-2019"},{toDate:utils.convertDate(this.state.toDate)})
        else Object.assign(this.filter,{fromDate: utils.convertDate(this.state.fromDate)},{toDate:utils.convertDate(this.state.toDate)})

        apiStatistic.ticketManager(this.filter, (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");
                console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let totalCount = 0;
                let totalPrice = 0;
                
                let totalCountBuyDT = 0;
                let totalPriceDT = 0;
                for (const product of result.countBuybyDT) {
                    totalPriceDT += product.totalPrice;
                    totalCountBuyDT += product.count;
                }
                
                let totalCountBuyVL = 0;
                let totalPriceVL = 0;
                for (const product of result.countBuybyVL) {
                    totalPriceVL += product.totalPrice;
                    totalCountBuyVL += product.count;
                }
                
                let totalCountWinDT = 0;
                let totalWinDT = 0;
                for (const product of result.countWinbyDT) {
                    totalWinDT += product.totalWin;
                    totalCountWinDT += product.count;
                }
                
                let totalCountWinVL = 0;
                let totalWinVL = 0;
                for (const product of result.countWinbyVL) {
                    totalWinVL += product.totalWin;
                    totalCountWinVL += product.count;
                }

                let totalCountWin = totalCountWinDT + totalCountWinVL
                let totalWin = totalWinDT + totalWinVL
                totalCount = totalCountBuyDT + totalCountBuyVL
                totalPrice = totalPriceDT + totalPriceVL

                result['totalCountWin'] = totalCountWin
                result['totalWin'] = totalWin
                result['totalCount'] = totalCount
                result['totalPrice'] = totalPrice
                result['totalCountBuyDT'] = totalCountBuyDT
                result['totalPriceDT'] = totalPriceDT
                result['totalCountBuyVL'] = totalCountBuyVL
                result['totalPriceVL'] = totalPriceVL
                result['totalCountWinDT'] = totalCountWinDT
                result['totalWinDT'] = totalWinDT
                result['totalCountWinVL'] = totalCountWinVL
                result['totalWinVL'] = totalWinVL
                this.setState({ ticketHistory: result, pageNum : Math.ceil(totalCount/this.filter.size)});
            }
        });
    }
    selectTicket(ticketId){

        if(ticketId){            
            getTicketInfo(ticketId,(err,data)=>{
            if(!err){
                this.setState({dataTicket:data,showModalTicket:true})
            }
            else Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
        })
        }
    }
    handExport(){
        this.setState({loading:true});
        let filter = {};
        if( this.state.fromDate!==null ) 
            Object.assign(filter,{fromDate: utils.convertDate(this.state.fromDate)})
        if( this.state.toDate!==null )
            Object.assign(filter,{toDate:utils.convertDate(this.state.toDate)})
        exportedExcel('prize/export',filter,(err,res)=>{
            if(err){
                Notification("error","Vui lòng giới hạn lại thời gian");
            } 
            this.setState({loading:false});
        })
    }

    getNo(cell,row,index){
        return this.state.size*(this.state.page-1)+index;
    }

    componentDidMount() {
        this.fnGetDepositWinPrize(1);
        apiAdmin.litStaff((err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.allOptionPos = [{label: "all", value: "all"}];
                this.activeOptionPos = [{label: "all", value: "all"}];
                result.forEach(staff => {
                    let posItem = {label: staff.username, value: staff.id, isActive: staff.isActive}
                    this.allOptionPos.push(posItem);
                    if(staff.isActive){
                        this.activeOptionPos.push(posItem);
                    }
                });
                console.log(this.activeOptionPos)
                this.setState({
                    listPos : this.activeOptionPos,
                    optionShowPosInactive : false
                });
            }
        });
    }
    toggle(component){
        this.setState({
            [component]: !this.state[component]
        });
    }
    render() {
        const tableRowEvents = {
            onClick: (e, row, rowIndex) => {
              console.log(row)
              this.selectTicket(row.ticketId)
            },           
         }
        return utils.isEmpty(this.state.ticketHistory) ? null : (
            <React.Fragment>
                {
                        this.state.dataTicket?(
                            <ModalTicket
                                ticketArray = {[this.state.dataTicket.ticket]}
                                showModalTicket = {this.state.showModalTicket}
                                staff={this.state.dataTicket.staff}
                                closeModal = {this.toggle.bind(this,"showModalTicket")}
                            />
                        ):null
                }
                <Row className='justify-content-center m-0'>
                    <FormGroup className='mb-3'>
                        <Label className="d-block">Thời gian</Label>
                        <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.fromDate}
                            onChange={date =>this.setState({fromDate: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormGroup>
                    <FormGroup className='mb-3 ml-3'>
                        <Label className="d-block">Nhân viên bán vé</Label>
                        <Select
                            className="header-select-statistic"
                            name='code'
                            placeholder="Nhân viên bán vé"
                            classNamePrefix='react-select'
                            options={this.state.listPos}
                            onChange={(value)=> this.handleToggleSelectPos(value)}
                        />
                    </FormGroup>
                    <div className='mt-4 p-2 d-flex'>
                        <Button className=" mb-3" color='primary' onClick={this.fnGetDepositWinPrize.bind(this,1)}>
                            Tìm kiếm
                        </Button>
                        {/* {
                            !this.state.loading
                            ? <Button  className="mx-2 mb-3" color='primary' onClick={this.handExport.bind(this)}>
                                    Xuất file
                                </Button>
                            : <LoadingSprinner/>
                        } */}
                    </div>
                </Row>
                <div className='p-3 w-120 borderRadius text-center'>
                    <Row>
                        <Col md='12'>
                            <Col md='12'>
                                    <table className="table table-bordered text-center">
                                        <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Count Buy</th>
                                            <th>Total Buy Amount</th>
                                            <th>Count Win</th>
                                            <th>Total Win Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <td colspan="5">Điện toán</td>
                                        {Object.keys(this.state.productList.dt).map(index => (
                                            <tr key={index}>
                                            <td>{this.state.productList.dt[index]}</td>
                                            <td>{utils.getCountByName(this.state.ticketHistory.countBuybyDT, this.state.productList.dt[index])}</td>
                                            <td>{utils.convertMoneyFomat(utils.getBuyByName(this.state.ticketHistory.countBuybyDT, this.state.productList.dt[index]))}</td>
                                            <td>{utils.getCountByName(this.state.ticketHistory.countWinbyDT, this.state.productList.dt[index])}</td>
                                            <td>{utils.convertMoneyFomat(utils.getWinByName(this.state.ticketHistory.countWinbyDT, this.state.productList.dt[index]))}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td>Tổng Điện toán</td>
                                            <td>{this.state.ticketHistory.totalCountBuyDT}</td>
                                            <td>{utils.convertMoneyFomat(this.state.ticketHistory.totalPriceDT)}</td>
                                            <td>{this.state.ticketHistory.totalCountWinDT}</td>
                                            <td>{utils.convertMoneyFomat(this.state.ticketHistory.totalWinDT)}</td>
                                        </tr>
                                        <td colspan="5">Vietlott</td>
                                        {Object.keys(this.state.productList.vl).map(index => (
                                            <tr key={index}>
                                            <td>{this.state.productList.vl[index]}</td>
                                            <td>{utils.getCountByName(this.state.ticketHistory.countBuybyVL, this.state.productList.vl[index])}</td>
                                            <td>{utils.convertMoneyFomat(utils.getBuyByName(this.state.ticketHistory.countBuybyVL, this.state.productList.vl[index]))}</td>
                                            <td>{utils.getCountByName(this.state.ticketHistory.countWinbyVL, this.state.productList.vl[index])}</td>
                                            <td>{utils.convertMoneyFomat(utils.getWinByName(this.state.ticketHistory.countWinbyVL, this.state.productList.vl[index]))}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td>Tổng Vietlott</td>
                                            <td>{this.state.ticketHistory.totalCountBuyVL}</td>
                                            <td>{utils.convertMoneyFomat(this.state.ticketHistory.totalPriceVL)}</td>
                                            <td>{this.state.ticketHistory.totalCountWinVL}</td>
                                            <td>{utils.convertMoneyFomat(this.state.ticketHistory.totalWinVL)}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Tổng tất cả</b></td>
                                            <td>{this.state.ticketHistory.totalCount}</td>
                                            <td>{utils.convertMoneyFomat(this.state.ticketHistory.totalPrice)}</td>
                                            <td>{this.state.ticketHistory.totalCountWin}</td>
                                            <td>{utils.convertMoneyFomat(this.state.ticketHistory.totalWin)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                            </Col>
                        </Col>
                    </Row>
                </div>
                <div className='py-3 mt-2 w-120 '>
                    <div className='text-center'>
                        <FontAwesomeIcon color='#2c7be5' style={{ fontSize: "30px" }} icon={faAward} /> <span className='content-title-bold'>Quản lý bán vé</span>
                    </div>
                    <Row className='pt-2 p-1 m-0 overflow-x-auto table-overflow-x'>
                        <BootstrapTable
                            id = "thong-ke-lich-su-ve"
                            className="table-staffmanagement"
                            bootstrap4
                            bordered={true}
                            keyField='id'
                            data={this.state.ticketHistory.data}
                            columns={[
                                {
                                    dataField: "createdDate",
                                    text: "Thời gian",
                                    sort: true,
                                    filter: textFilter({
                                        placeholder: "Nhập ngày tháng",
                                    }),
                                    formatter: function Formatter(cell) {
                                        if (cell === null) {
                                            return "";
                                        } else {
                                            return cell;
                                        }
                                    },
                                    headerStyle: () => {
                                        return {textAlign: "center" };
                                    },
                                },
                                {
                                    dataField: `id`,
                                    text: "ID Order",
                                    filter: textFilter({
                                        placeholder: "Nhập ID",
                                    }),
                                    headerStyle: () => {
                                        return {textAlign: "center" };
                                    },
                                },
                                {
                                    dataField: `fullName`,
                                    text: "Tên khách hàng",
                                    filter: textFilter({
                                        placeholder: "Nhập tên khách hàng",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "";
                                        } else {
                                            return cell;
                                        }
                                    },
                                    headerStyle: () => {
                                        return {textAlign: "center" };
                                    },
                                },
                                {
                                    dataField: `phoneNumber`,
                                    text: "Số điện thoại",
                                    filter: textFilter({
                                        placeholder: "Nhập số điện hàng",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "";
                                        } else {
                                            return cell;
                                        }
                                    },
                                    headerStyle: () => {
                                        return {textAlign: "center" };
                                    },
                                },
                                {
                                    dataField: `name`,
                                    text: "Loại vé",
                                    filter: selectFilter({
                                        placeholder: "Chọn loại vé",
                                        options: {
                                            "Keno":"Keno",
                                            "MAX 3D PRO":"MAX 3D PRO",
                                            "MAX 3D":"MAX 3D",
                                            "MAX 3D+":"MAX 3D+",
                                            "POWER 6/55":"POWER 6/55",
                                            "Mega 6/45":"Mega 6/45",
                                            "Lô tô 2 cặp số, 3 cặp số, 4 cặp số ":"Lô tô 2 cặp số, 3 cặp số, 4 cặp số ",
                                            "Lô tô 2 số, 3 số, 5 số":"Lô tô 2 số, 3 số, 5 số",
                                            "Điện toán 6x36": "Điện toán 6x36",
                                            "Thần tài 4": "Thần tài 4",
                                            "Điện toán 123": "Điện toán 123"
                                        }
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        if (cell === null) {
                                            return "";
                                        } else {
                                            return cell;
                                        }
                                    },
                                    headerStyle: () => {
                                        return {textAlign: "center" };
                                    },
                                },
                                {
                                    dataField: `value`,
                                    text: "Số ghi trên vé",
                                    filter: textFilter({
                                        placeholder: "Nhập số vé",
                                    }),
                                    formatter: function Formatter(cell, row) {
                                        try {
                                            const parsedData = JSON.parse(cell[0]['value']);
                                            return(
                                                    parsedData.array.map(({number},index)=>{
                                                        return (
                                                            <div className="w-100 my-1">
                                                            {
                                                                number.map((n,key)=>{
                                                                    if(utils.isEmpty(n)){
                                                                        console.log(1);
                                                                        return ("");
                                                                    }
                                                                    return( <span className="mx-1" key={index*10 + key}>{n}</span> )
                                                                })        
                                                            }
                                                            </div>
                                                        )
                                                    })
                                                )
                                        }
                                        catch (error) {
                                            console.error("Error parsing JSON or rendering components:", error);
                                            return "display error";
                                        }
                                    },
                                    headerStyle: () => {
                                        return { width: "250px", textAlign: "center" };
                                    },
                                },
                                {
                                    dataField: "termArray",
                                    text: "Kì quay",
                                    filter: textFilter({
                                        placeholder: "Kì quay",
                                    }),
                                    formatter: function Formatter(cell) {
                                        if (cell === null) {
                                            return "";
                                        } else {
                                            const parsedCell = JSON.parse(cell);
                                            return parsedCell.array.join(' ');
                                        }
                                    },
                                    headerStyle: () => {
                                        return { width: "100px", textAlign: "center" };
                                    },
                                },
                                {
                                    dataField: "totalPrice",
                                    text: "Số tiền mua",
                                    filter: textFilter({
                                        placeholder: "Nhập số tiền",
                                    }),
                                    sort: true,
                                    formatter: function Formatter(cell) {
                                        if (cell === null) {
                                            return "";
                                        } else {
                                            return utils.getMoneyFormat(cell);
                                        }
                                    },
                                    headerStyle: () => {
                                        return {textAlign: "center" };
                                    },
                                },
                            ]}
                            rowEvents=  {tableRowEvents }
                            filter={filterFactory()}
                        />
                    </Row>
                    <Row className="mt-2 ml-3">
                        <select className="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            {
                                utils.isEmpty(this.state.ticketHistory) ? null
                                :(
                                    <div className="float-right px-2">
                                        <Button disabled={this.filter.page===1} onClick={this.fnGetDepositWinPrize.bind(this,"-")}>{`<`}</Button>
                                        {
                                            utils.range(1,10).map((index)=>{
                                                return(
                                                    <Button 
                                                        key = {index}
                                                        className={`${this.filter.page === index ? "btn-danger" : null}`}
                                                        hidden={index >  this.state.pageNum}
                                                        onClick={this.fnGetDepositWinPrize.bind(this,index)}
                                                    >
                                                        {index}
                                                    </Button>
                                                )
                                            })
                                        }
                                        <Button hidden={this.filter.page <= 10|| this.filter.page===this.state.pageNum} className="btn-danger">{`${this.filter.page}`}</Button>
                                        <Button hidden={this.state.pageNum<=10} className={`${this.filter.page === this.state.pageNum ? "btn-danger" : null}`} onClick={this.fnGetDepositWinPrize.bind(this,this.state.pageNum)}>{`...${this.state.pageNum}`}</Button>
                                        <Button disabled={this.filter.page >=  this.state.pageNum} onClick={this.fnGetDepositWinPrize.bind(this,"+")}>{`>`}</Button>
                                    </div>
                                )
                            }
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;